import globalStore from '@/services/global.state';
import {goTo} from './route';
import {SafeAny} from '@/types';
import {getSideList} from '@/services/global.service';
import {getAdjustAttribution} from './adjust';
import DeviceInfo from 'react-native-device-info';
import {homePage} from '@/config';
import {inApp} from './interface';
import Clipboard from '@react-native-clipboard/clipboard';

export function needLoginBeforeDownload() {
  return (
    !globalStore.token &&
    (new URL(location.href).searchParams.get('pixelid') != null ||
      localStorage.getItem('pixelid') != null)
  );
}

export function stopBeforeDownload() {
  if (needLoginBeforeDownload()) {
    goTo('Login', {backPage: homePage});
    return true;
  }
  return false;
}

export function getImgs(sucCall: SafeAny, failCall?: SafeAny) {
  getSideList()
    .then(r => {
      sucCall(r);
    })
    .catch(e => {
      failCall && failCall(e);
      console.log('e', e);
    });
}
export function getDeviceCode() {
  if (globalStore.isWeb) {
    const adid = getAdjustAttribution()?.adid;
    if (adid) {
      localStorage.setItem('adjust_adid', adid);
      return adid;
    } else {
      let localAdid = localStorage.getItem('adjust_adid');
      if (!localAdid) {
        localAdid =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        localStorage.setItem('adjust_adid', localAdid);
      }
      return localAdid;
    }
  } else {
    return DeviceInfo.getAndroidId();
  }
}
function getRegisterChannel(userInviteCode?: string) {
  const attr = getAdjustAttribution();
  const trackerName = attr && attr.tracker_name;
  return trackerName && trackerName !== 'Organic'
    ? 'Promotion' /*推广*/
    : userInviteCode // 只有落地页还有邀请码
    ? 'AppShare'
    : 'Organic';
}

function getDeviceInfo() {
  const ua = navigator.userAgent;
  const info = {
    deviceBrand: 'Unknown',
    deviceModel: 'Unknown',
  };
  try {
    if (inApp) {
      const matchStr = ua.split('Mobile')?.[1]?.split(';')?.[1];
      const [deviceBrand, deviceModel] =
        matchStr?.split(';')?.[0]?.split('/') || [];
      info.deviceBrand = deviceBrand || 'Unknown';
      info.deviceModel = deviceModel || 'Unknown';
    } else {
      if (ua.indexOf('iPhone') > -1) {
        console.log('iPhone');
        info.deviceBrand = 'iPhone';
        info.deviceModel =
          ua.split('Mobile/')?.[1]?.split(' ')?.[0] || 'Unknown';
      } else if (ua.indexOf('Android') > -1) {
        info.deviceBrand = 'Android';
        info.deviceModel =
          ua.split('Mobile')?.[1]?.split(' ')?.[0]?.split('/')?.[1] ||
          'Unknown';
      }
    }
  } catch (e) {
    console.log('Error:', e);
  }
  return info;
}

export function getAttribution(userInviteCode?: string) {
  return {
    registerChannel: getRegisterChannel(userInviteCode),
    platform: inApp ? 'app' : 'h5',
    ...getDeviceInfo(),
  };
}

export function copyText(text: string) {
  if (inApp) {
    try {
      (window as any).Android?.copyToClipboard(text);
    } catch (e) {
      Clipboard.setString(text);
    }
  } else {
    Clipboard.setString(text);
  }
}
/**
 * @description 返回ab版本
 * */
export function getUserVersion() {
  const getUniqueIdentifier = () => {
    const key = 'APP_UNIQUE_UUID';
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key)!;
    } else {
      const uuid = Math.random().toString(36).substring(2);
      localStorage.setItem(key, uuid);
      return uuid;
    }
  };
  const uniqueId = getUniqueIdentifier();
  let sum = 0;
  for (let i = 0; i < uniqueId.length; i++) {
    sum += uniqueId.charCodeAt(i);
  }
  return sum % 2 === 0;
}

const gameRates = {
  DICE: [71.18, 71.08, 65.81, 66.84, 68.17, 69.67, 69.02, 67.34, 67.06, 68.73],
  COLOR: [74.7, 64.08, 79.39, 72.09, 77.71, 66.3, 74.95, 60.1, 81.46, 70.5],
  Quick: [64.77, 84.61, 80.34, 65.25, 70.03, 71.37, 73.63, 70.39, 62.17, 63.04],
  Racecar: [
    70.29, 60.75, 63.83, 70.96, 59.9, 69.14, 69.77, 71.65, 57.11, 61.88,
  ],
};

const numberRates = {
  QUICK: [234, 456, 567, 789, 890, 901, 123, 987, 876, 765],
  MIX: [113, 287, 357, 498, 555, 669, 787, 832, 913, 489],
  KERALA: [876, 399, 763, 349, 780, 640, 674, 922, 209, 407],
  MATKA: [639, 907, 109, 789, 532, 611, 628, 732, 599, 699],
  OTHERS: [739, 507, 309, 989, 132, 691, 678, 739, 699, 879],
};

const getRate = (gameType: string, listType: any) => {
  // 获取当前时间戳（毫秒）
  const timestamp = Date.now();
  // 将时间戳转换为小时数（向下取整）
  const hours = Math.floor(timestamp / (1000 * 60 * 60));
  // 每2小时一个周期
  const periodIndex = Math.floor(hours / (5 / 6));

  // 获取对应游戏类型的胜率数组
  const rates = listType[gameType];
  if (!rates) {
    return null; // 如果游戏类型不存在，返回null
  }

  // 使用周期索引对数组长度取模，确保循环使用数组中的所有数据
  const index = periodIndex % rates.length;

  return rates[index];
};

export const getGameRates = (gameType: string) => getRate(gameType, gameRates);
export const getNumberRates = (gameType: string) =>
  getRate(gameType, numberRates);
